<template>
  <div class="flex justify-center">
    <q-card
      class="q-pa-md no-shadow"
      :style="{ 'font-family': settings.typography }"
      style="max-width: 500px"
    >
      <div class="row items-center">
        <q-btn
          dense
          flat
          icon="arrow_back_ios"
          v-close-popup
          class="q-ml-md"
          to="/login"
          style="color: black; font-size: 12px"
        >
          <q-space />
        </q-btn>
      </div>
      <div>
        <img class="q-ma-lg img-login" :src="settings.logo ? settings.logo : defaultImageApp" style="max-width: 200px" />
        
      </div>
      <q-form
        autocomplete="off"
        @submit.prevent="requestResetPassword"
        method="post"
      >
        <q-list>
          <q-item class="q-ma-none q-pa-none">
            <q-item-section>
              <q-item-label class="text-h6">
                {{ $t("Login.get-password") }}</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-item class="q-ma-none q-pa-none">
            <q-item-section>
              <q-item-label for="email" class="q-ma-sm"
                >{{ $t("Login.introduce-mail") }} {{ settings.system_name }}
                {{ $t("Login.get-link") }}</q-item-label
              >

              <q-input
                type="email"
                id="email"
                class="form-control q-ma-md"
                :placeholder="$t('General.mail-example')"
                v-model="email"
                required
                rounded
                outlined
              />
            </q-item-section>
          </q-item>
        </q-list>
        <div class="row justify-center">
          <q-btn
            class="button-blue"
            :label="$t('Login.apply-for')"
            type="submit"
            :style="{ background: settings.color_primary }"
            style="color: white"
          >
          </q-btn>
        </div>
      </q-form>
    </q-card>
  </div>

  <q-dialog persistent v-model="loading" style="max-width: 200px">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-section class="text-center">
        <div class="text-body1 q-pb-md">{{ $t("General.wait") }}</div>
        <q-spinner size="40px" />
      </q-card-section>
    </q-card>
  </q-dialog>

  <q-dialog persistent v-model="wrongEmail">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-actions align="center">
        <q-btn icon="close" color="red" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">{{ $t("General.problem") }}.</div>
        <q-item-label class="text-center q-mt-md">{{
          $t("Login.problem-password-2")
        }}</q-item-label>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          :label="$t('Login.try-again')"
          :style="{ 'background-color': settings.color_accent }"
          v-close-popup
        />
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog persistent v-model="emailReceived">
    <q-card :style="{ 'font-family': settings.typography }">
      <q-card-actions align="center">
        <q-btn icon="done" color="green" size="25px" flat />
      </q-card-actions>
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t("Login.check-mail") }}
        </div>
      </q-card-section>
      <q-card-actions align="center">
        <q-btn
          class="btn-home"
          to="/login"
          :label="$t('Login.login')"
          :style="{ 'background-color': settings.color_accent }"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import axios from "../../../../../services/Api";
import settings from "@/mixins/settings";

export default {
  mixins: [settings],

  data() {
    return {
      email: "",
      has_error: false,
      loading: false,
      wrongEmail: false,
      emailReceived: false,
    };
  },
  methods: {
    async requestResetPassword() {
      this.loading = true;
      
      const body = new FormData();
      body.append("email", this.email);

      await axios.get("/sanctum/csrf-cookie");
      await axios.post("front-catalog/forgot-password", body)
        .then(() => {
          this.loading = false;
          this.emailReceived = true;
        })
        .catch((error) => {
          console.error(error);
          this.wrongEmail = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-card {
  border-radius: 25px 25px 25px 0px;
}
.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
}

.btn-home {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  text-transform: capitalize;
  width: 100%;
  color: white;
  margin: 0;
}
</style>
